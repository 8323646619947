<template>
    <div class="instructions-column">
        <div class="logo-container">
            <img
                :src="branding.primaryLogoUrl"
                class="logo"
                alt=" "
            >
        </div>
        <div class="play-and-win">
            {{ $t('externalLeaderboardRoute.playAndWin[0]') }}
            <span class="and"> {{ $t('externalLeaderboardRoute.playAndWin[1]') }}</span>
            {{ $t('externalLeaderboardRoute.playAndWin[2]') }}
        </div>
        <div class="big-prize-container">
            <transition name="fade">
                <img :key="currentImage" :src="currentImage" alt="prize image">
            </transition>
        </div>
        <div class="scan-power-container">
            <div class="scan-container">
                <div class="qr-img-container">
                    <img :src="leaderboardConfig.qrImageUrl" alt="qr image" class="qr-img">
                </div>
                <span class="scan-text">
                    {{ $t('externalLeaderboardRoute.scanText') }}
                </span>
            </div>
            <div class="powered-by">
                {{ $t('externalLeaderboardRoute.poweredBy') }} <mpass-logo-svg />
            </div>
        </div>
    </div>
</template>

<script>
    import MpassLogoSvg from '@/components/svg/MpassLogoSVG'
    export default {
        components: { MpassLogoSvg },
        data() {
            return {
                currentIndex: 0,
                reloadLeaderboardTimer: null,
                slideDuration: 8000
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            hasFirstPlayerIcon() {
                return !!this.leaderboardConfig.firstPlayerIconUrl
            },
            leaderboardConfig() {
                return this.$store.getters.getLeaderboardConfig
            },
            currentImage() {
                return this.leaderboardConfig?.prizes?.[this.currentIndex]?.imageUrl
            }
        },
        destroyed() {
            clearInterval(this.reloadLeaderboardTimer)
        },
        mounted() {
            this.reloadLeaderboardTimer = setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.leaderboardConfig.prizes.length
            }, this.slideDuration)
        }
    }
</script>

<style scoped>
/* RIGHT COL STARTS HERE */
.instructions-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.logo-container {
    margin-top: 6rem;
    max-width: 600px;
}

.logo {
    width: 100%;
    height: auto;
}

.play-and-win {
    color: var(--info-color);
    margin: 10rem auto 4rem;
    font-size: 10.54rem;
    font-weight: bold;
    text-shadow: 0 13px 27px rgb(0 0 0 / 25%);
}

.and {
    font-weight: normal;
}

.big-prize-container {
    width: 800px;
    height: 600px;
    position: relative;
}

.high-scorer-wins {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    place-items: center;
    width: 290px;
    height: 290px;
    color: var(--white);
    text-align: center;
    background: #f00;
    border-radius: 2.27rem;
    font-size: 3.5rem;
    line-height: 1.25;
    font-weight: 800;
}

.fade-enter-active,
.fade-leave-active {
    transition: transform 2s ease-in-out;
}

.fast-fade-enter-active {
    transition: opacity 2s ease-in;
}

.fast-fade-leave-active {
    transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
    transform: scale(0);
}

.fast-fade-enter,
.fast-fade-leave-to {
    opacity: 0;
}

.big-prize-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.scan-power-container {
    margin: auto auto 7.5rem;
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.scan-container {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.scan-text {
    width: 40%;
    color: var(--info-color);
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 3.125rem;
}

.qr-img-container {
    border-radius: 2.5rem;
    border: 1rem solid var(--white);
    max-width: 260px;
}

.qr-img {
    width: 100%;
    height: auto;
}

.powered-by {
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    font-size: 2.7rem;
    text-shadow: 0 13px 27px rgb(0 0 0 / 25%);
}
</style>
