<template>
    <div class="leaderboard-table-container">
        <div
            v-for="(rank) in ranks"
            :key="rank.id"
            class="rank-row-container"
        >
            <span class="position">
                <span>{{ rank.rank }}</span>
            </span>
            <div class="rank-row">
                <div class="avatar-container">
                    <avatar
                        :avatar="rank.avatar? rank.avatar : null"
                    />
                </div>
                <div class="player-info">
                    <span class="player-name">
                        {{ rank.name }}
                    </span>
                    <div class="info-container">
                        <span class="points-earned-container">
                            <span class="points-earned">
                                {{ $t('externalLeaderboardRoute.tablePoints') }}:
                            </span>
                            <div class="points-text-container">
                                <span class="points-text">{{ rank.totalScore }}</span>
                                <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star points-text" />
                            </div>
                        </span>
                        <span class="time-spent-container">
                            <span class="time-spent">{{ rank.digitString }}</span>
                            <timer-svg class="timer-icon" color="star-color" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Avatar from '@/components/Avatar'
    import TimerSvg from '@/components/svg/TimerSVG'
    export default {
        components: { Avatar, TimerSvg },
        props: {
            ranks: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>
.leaderboard-table-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(10, 1fr);
    row-gap: 2rem;
    column-gap: 4rem;
}

.position {
    letter-spacing: 1px;
    font-size: 1.25rem;
    color: var(--secondary-color);
}

.position span {
    font-size: 1.75rem;
    font-weight: bold;
}

.rank-row-container {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.1rem;
    color: var(--info-color);
    border-radius: 1.25rem;
    gap: 1.25rem;
    position: relative;
}

.rank-row {
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--white);
    gap: 1.5rem;
    padding: 0.75rem 1rem 0.75rem 0.75rem;
    box-shadow: 0 0 45px 4px rgb(28   103 157 / 20%);
    border-radius: 3.3rem;
}

.avatar-container {
    max-width: 55px;
}

.player-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}

.player-info .player-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 220px;
    font-size: 1.7rem;
    font-weight: bold;
}

.info-container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    font-size: 1.5rem;
}

.time-spent-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.points-earned-container {
    display: flex;
    align-items: center;
}

.timer-icon {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 25%));
    width: 1.75rem;
    height: 1.75rem;
}

.time-spent {
    font-weight: 400;
    font-size: 1.5rem;
}

.points-earned {
    font-weight: 400;
}

.points-text-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    min-width: 120px;
}

.info-container .points-text {
    font-weight: 700;
    color: var(--info-color);
}

svg.star path {
    color: var(--star-color);
}

.star {
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 25%));
}

</style>
