<template>
    <div class="video-column">
        <div class="scan-column">
            <div class="scan-container">
                <span class="scan-text">
                    {{ $t('externalLeaderboardRoute.scanText') }}
                </span>
                <div class="qr-img-container">
                    <img :src="leaderboardConfig.qrImageUrl" alt="qr image" class="qr-img">
                </div>
            </div>
            <div class="powered-by">
                {{ $t('externalLeaderboardRoute.poweredBy') }} <mpass-logo-svg />
            </div>
        </div>
        <div class="video-container">
            <video
                v-if="leaderboardConfig.videoUrl"
                ref="leaderboardVideo"
                :poster="leaderboardConfig.videoPosterUrl || ''"
                class="video"
                autoplay
                muted
                loop
                playsinline
                preload="auto"
            >
                <source :src="leaderboardConfig.videoUrl" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</template>

<script>
    import MpassLogoSvg from '@/components/svg/MpassLogoSVG'
    export default {
        components: { MpassLogoSvg },
        computed: {
            leaderboardConfig() {
                return this.$store.getters.getLeaderboardConfig
            }
        }
    }
</script>

<style scoped>
.video-column {
    --video-width: 1216px;

    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
}

.scan-column {
    margin-top: auto;
    margin-bottom: 6rem;
    width: calc(100% - var(--video-width));
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12rem;
}

.scan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

.scan-text {
    color: var(--info-color);
    font-weight: bold;
    text-align: center;
    font-size: 2.6rem;
    line-height: 2.8rem;
    max-width: 260px;
}

.qr-img-container {
    border-radius: 2.5rem;
    border: 1rem solid var(--white);
    max-width: 260px;
}

.qr-img {
    width: 100%;
    height: auto;
}

.powered-by {
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    font-size: 2.75rem;
    text-shadow: 0 13px 27px rgb(0 0 0 / 25%);
}

.video-container {
    width: var(--video-width);
    position: relative;
}

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

</style>
