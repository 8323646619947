<template>
    <div class="route-container">
        <clouds-4k v-if="!isVideoLeaderboard" />
        <div class="custom-col left-col">
            <div class="leaderboard-header">
                <span class="leaderboard-title">
                    {{ $t('externalLeaderboardRoute.title') }}
                </span>
                <span class="total">{{ $t('externalLeaderboardRoute.totalPlayers') }}: <strong>{{ totalPlayers }}</strong></span>
            </div>
            <div v-if="isVideoLeaderboard && leaderboardConfig.prizeImageUrl" class="big-price-container">
                <img :src="leaderboardConfig.prizeImageUrl" alt="big prize image">
            </div>
            <div class="top-notch-container">
                <div
                    v-for="rank of topThree"
                    :key="rank.id"
                    class="top-rank"
                >
                    <span class="rank-number">
                        #{{ rank.rank }}
                    </span>
                    <img
                        v-if="!rank.avatar"
                        class="avatar"
                        :src="branding.defaultAvatarUrl"
                        alt="default avatar"
                    >
                    <div class="avatar-container">
                        <img
                            v-if="rank.avatar && rank.avatar.imageUrl"
                            :src="`${rank.avatar.imageUrl}`"
                            class="avatar"
                            alt="user avatar"
                        >
                        <img
                            v-if="rank.rank === 1 && leaderboardConfig.firstPlayerIconUrl"
                            :src="leaderboardConfig.firstPlayerIconUrl"
                            alt="first player icon"
                            class="winner-badge first"
                        >
                        <img
                            v-if="rank.rank === 2 && leaderboardConfig.secondPlayerIconUrl"
                            :src="leaderboardConfig.secondPlayerIconUrl"
                            alt="second player icon"
                            class="winner-badge second"
                        >
                        <img
                            v-if="rank.rank === 3 && leaderboardConfig.thirdPlayerIconUrl"
                            :src="leaderboardConfig.thirdPlayerIconUrl"
                            alt="third player icon"
                            class="winner-badge third"
                        >
                    </div>
                    <span class="top-rank-name">
                        {{ rank.name }}
                    </span>
                    <div class="top-rank-points-container">
                        <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star" />
                        <span class="points-text">{{ rank.totalScore }}</span>
                        <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star" />
                    </div>
                    <span class="time-spent-container">
                        <timer-svg color="white" class="timer-icon" />
                        <span class="time-spent">{{ rank.digitString }}</span>
                    </span>
                </div>
            </div>
            <div class="leaderboard-container">
                <external-leader-board-table :ranks="ranks" />
            </div>
        </div>
        <div class="custom-col right-col">
            <external-leader-board-video v-if="isVideoLeaderboard" />
            <external-leader-board-instructions
                v-else
            />
        </div>
    </div>
</template>

<script>
    import ExternalLeaderBoardTable from '@/components/external-leaderboard/ExternalLeaderBoardTable'
    import ExternalLeaderBoardInstructions from '@/components/external-leaderboard/ExternalLeaderBoardInstructions'
    import ExternalLeaderBoardVideo from '@/components/external-leaderboard/ExternalLeaderBoardVideo'
    import Clouds4k from '@/components/external-leaderboard/Clouds4K'
    import rankService from '@/services/rank-service'
    import dateUtils from '@/utils/date-conversion'
    import TimerSvg from '@/components/svg/TimerSVG'
    export default {
        components: { ExternalLeaderBoardTable, TimerSvg, Clouds4k, ExternalLeaderBoardInstructions, ExternalLeaderBoardVideo },
        data() {
            return {
                ranks: [],
                topThree: [],
                totalPlayers: 0,
                reloadLeaderboardTimer: null,
                isVideoLeaderboard: false
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            leaderboardConfig() {
                return this.$store.getters.getLeaderboardConfig
            }
        },
        beforeMount() {
            this.$store.dispatch('logout')
            this.fetchRanks()
            if (this.$route.query.video) {
                this.isVideoLeaderboard = true
            }
        },
        mounted() {
            this.resetReloadLeaderboardTimer()
        },
        destroyed() {
            clearInterval(this.reloadLeaderboardTimer)
        },
        methods: {
            fetchRanks() {
                rankService.getRanks(20).then((res) => {
                    this.ranks = res.data.ranks
                    this.totalPlayers = res.data.totalPlayers
                    this.ranks.map((rank) => {
                        rank.digitString = dateUtils.getPaddedDigitsString(rank.totalPlayDurationMs)
                        return rank
                    })
                    this.topThree = this.ranks.slice(0, 3)
                })
            },
            resetReloadLeaderboardTimer() {
                clearInterval(this.reloadLeaderboardTimer)

                this.reloadLeaderboardTimer = setInterval(() => {
                    this.fetchRanks()
                }, 10 * 60 * 1000)
            }
        }
    }
</script>

<style scoped>
.route-container {
    width: 100%;
    max-width: 3840px;
    height: 100%;
    max-height: 2160px;
    font-family: Ubuntu, sans-serif;
    color: var(--white);
    text-align: left;
    background: #24c1ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-col {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.left-col {
    width: 55%;
    margin-left: 6rem;
    justify-content: space-between;
    align-items: flex-start;
}

.right-col {
    width: 45%;
}

.leaderboard-header {
    margin: 5rem 0 0 4rem;
    display: flex;
    flex-direction: column;
}

.leaderboard-title {
    font-size: 6.175rem;
    font-weight: bold;
    line-height: 6rem;
}

.total {
    font-size: 2.8rem;
    color: var(--info-color);
}

.big-price-container {
    position: absolute;
    top: 10vh;
    left: 50vw;
    max-width: 500px;
    animation: womble 2s linear infinite;
}

.big-price-container img {
    width: 100%;
    height: auto;
}

@keyframes womble {
    0% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

.top-notch-container {
    margin: 4rem auto 0;
    width: 100%;
    max-width: 1300px;
    height: 625px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 10rem;
}

.top-rank {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    height: 100%;
}

.avatar-container {
    width: 100%;
    position: relative;
}

.avatar {
    border-radius: 50%;
    border: 5px solid var(--white);
    width: 100%;
}

.winner-badge {
    border-radius: 50%;
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 20%));
}

.winner-badge.first {
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 125px;
    height: 125px;
}

.winner-badge.second {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 90px;
    height: 90px;
}

.winner-badge.third {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
}

.top-rank:first-child {
    order: 2;
    max-width: 285px;
}

.top-rank:nth-child(2) {
    order: 1;
    max-width: 200px;
}

.top-rank:last-child {
    order: 3;
    max-width: 144px;
}

.top-rank:first-child .rank-number {
    font-size: 5.25rem;
}

.top-rank:nth-child(2) .rank-number,
.top-rank:last-child .rank-number {
    font-size: 3.7rem;
}

.top-rank-name {
    color: var(--info-color);
}

.top-rank:first-child .top-rank-name {
    font-size: 2.4rem;
}

.top-rank:nth-child(2) .top-rank-name,
.top-rank:last-child .top-rank-name {
    font-size: 1.9rem;
}

.top-rank-points-container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    line-height: 4.3125rem;
}

.top-rank-points-container .points-text {
    font-weight: 700;
    letter-spacing: 2px;
    color: var(--info-color);
}

.top-rank:first-child .top-rank-points-container {
    font-size: 6.1875rem;
}

.top-rank:nth-child(2) .top-rank-points-container,
.top-rank:last-child .top-rank-points-container {
    font-size: 4.3125rem;
}

.time-spent-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.timer-icon {
    width: 2.2rem;
    height: 2.2rem;
}

.time-spent {
    font-size: 2.2rem;
}

.leaderboard-container {
    width: 100%;
    max-width: 2050px;
    margin: 0 auto;
    background: #fff;
    padding: 3rem 4rem 2rem;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
}

svg.star path {
    color: #f6c900;
}

.star {
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 40%));
    font-size: 2.5rem;
}

</style>
